.legalAidPage {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  background: var(--brand-white);
  top: 0;
  left: 0;
  padding: 1rem 6rem;
}

.legalAidPageScope {
  display: flex;
}

.legalAidPageScope article {
  flex: 1;
  padding: 1rem;
  height: auto;
}

.articleScope {
  position: relative;
}
.means,
.merit {
  background: var(--brand-yellow);
  height: 200px;
  margin-bottom: 1em;
}

.bottomMention {
  margin-top: 2rem;
}

.referralToSolicitor {
  width: 100%;
  padding: 1rem;
  border: 2px solid;
  margin-bottom: 2rem;
}
.marginLi {
  margin-left: 2rem;
}
