.glossaryBox {
  width: 100%;
  height: 100vh;
  position: absolute;
  background: var(--brand-white);
  top: 0;
  left: 0;
  padding: 1rem 6rem;
}

.hideGlossaryBtn,
.hideLegalAidBtn,
.hideLegalBtn,
.hideMeritBtn,
.hideMeansBtn {
  color: var(--brand-darker-green3);
  float: right;
  margin: 0rem 4rem 4rem 0rem;
}

.keyTerms {
  font-weight: bold;
}

.visible {
  display: none;
}

.bold {
  font-weight: bold;
}

.label {
  background-color: var(--brand-light-gray);
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  margin-left: 1em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: var(--brand-white);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
