/* /////////////////general///////////// */

.link {
  text-decoration: underline;
  color: var(--brand-dark-green);
  cursor: pointer;
  padding-right: 0.5rem;
  transition: 0.3s;
}

.rebrandLink {
  text-decoration: none;
  color: var(--brand-black);
}

.rebrandLink:hover {
  color: var(--brand-light-green) !important;
}

.link-inHeader {
  text-decoration: none;
  color: var(--brand-dark-green);
  cursor: pointer;
  padding-right: 0.5rem;
}

.link-inHeader:hover {
  text-decoration: underline;
}

.NextButton {
  text-decoration: none;
  color: var(--brand-white);
  background-color: var(--brand-light-green);
  cursor: pointer;
  border-radius: 5px;
  padding: 0.7rem 1rem;
  transition: 0.3s;
}

.NextButton:hover {
  background: var(--brand-dark-green);
}
.next-noRatio {
  margin-bottom: 30px;
}

.NextButtonPassive {
  text-decoration: none;
  color: var(--brand-light-gray);
  cursor: default;
  background-color: var(--brand-lighter-gray);
  border-radius: 5px;
  padding: 0.7rem 1rem;
}

.link:hover,
.hideGlossaryBtn:hover,
.hideLegalAidBtn:hover {
  color: var(--brand-darker-green2);
  cursor: pointer;
}

h1 {
  font-size: 4rem;
  color: var(--brand-darker-green);
  margin: 0 0 3.5rem -0.5rem;
  line-height: 4rem;
  text-align: left;
}

.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}

p {
  margin: 1rem 0;
  font-weight: 300;
}

h3 {
  font-size: 1.3rem;
  margin: 1rem 0;
}

b {
  color: var(--brand-blue);
}

.next {
  position: relative;
  top: 2rem;
  left: 1.5rem;
}

.next-noRatio {
  position: relative;
  top: 2rem;
}

.info_Area {
  display: flex;
}

.main_Info_Area {
  width: 50%;
  padding: 0 6rem 1rem 8rem;
}

.listContainer {
  padding-left: 2em;
}

.noBullets > * {
  list-style-type: none;
  margin-bottom: 0.3em;
}

.aside_Info_Area {
  width: 50%;
  min-height: 100vh;
  background: var(--brand-lighter-gray2);
  padding: 1rem;
}

.aside_Info_Area_glossary {
  width: 50%;
  min-height: 100vh;
  background: var(--brand-lighter-gray2);
  padding: 1rem;
  padding-top: 6rem;
  display: flex;
  flex-direction: column;
}

.infoBoxTitle {
  color: var(--brand-lighter-green);
  font-size: 0.8rem;
}

.glossary_Initials {
  text-align: center;
}

.glossary_letters_container {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0 2rem 0;
}

.glossary_letters {
  text-align: center;
}

.gridContainer {
  display: grid;
  grid-template-columns: 1.75rem;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  justify-self: center;
}

.grid {
  grid-row: 1 / 1;
  padding: 5px;
  display: block;
  margin: 2rem;
  margin-right: auto;
  align-self: center;
  justify-self: center;
  padding: 1rem;
}

.grid-fire {
  grid-column: 1;
  grid-row: 1 / 3;
  padding: 5px;
  display: block;
  align-self: center;
}
.grid-titles {
  grid-column: 2;
  grid-row: 1 / 2;
  align-content: left;
  display: block;
  justify-content: baseline;
  color: var(--brand-light-orange);
  font-size: 0.8rem;
  font-style: italic;
  margin-left: 1rem;
  text-transform: uppercase;
}

.grid-subtitles {
  grid-column: 2;
  grid-row: 2 / 3;
  align-content: left;
  vertical-align: top;
  margin-left: 1rem;
}

.aside_flashpoint {
  border: 2px solid var(--brand-light-orange);
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  margin-top: 4rem;
}

.bullet {
  padding-left: 2rem;
  padding-bottom: 1rem;
}

.bulletInBullet {
  padding: 0rem 0rem 0.5rem 4rem;
}

.link_inline_font {
  font-size: 18px;
}

.cat_style {
  padding: 1rem;
  transition: 0.3s;
  width: 50%;
}
.cat_style:hover {
  color: var(--brand-white);
  background: var(--brand-lighter-green);
  cursor: pointer;
}

.cat_link {
  text-decoration: none;
  color: var(--brand-black);
}
