.green_banner{
    height: 20px;
    width: 100%;
    background: rgb(73, 170, 73);
    color: rgb(73, 170, 73);
}

.green_banner_slim{
    height: 10px;
    width: 100%;
    background: rgb(39, 88, 39);
    color: rgb(39, 88, 39);
  
}
