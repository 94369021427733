:root {
  --brand-light-green: #31cd4f;
  --brand-lighter-green: rgb(66, 158, 66);
  --brand-dark-green: #459644;
  --brand-darker-green: #2d666d;
  --brand-darker-green2: rgb(39, 88, 39);
  --brand-darker-green3: rgba(45, 102, 109, 1);
  --brand-black: #000;
  --brand-white: #fff;
  --brand-lighter-gray2: #efefef;
  --brand-lighter-gray: #c4c4c4;
  --brand-light-gray: #8b8b8b;
  --brand-light-orange: #ff0000;
  --brand-blue: blue;
  --brand-yellow: yellow;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Lato', sans-serif;
  letter-spacing: 0rem;
  text-align: left;
  font-size: 1.1rem;
  line-height: 1.5rem;
  word-spacing: 0rem;
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', 'Lato',
    monospace;
}

.agreementCheck {
  padding: 0.5rem;
}

.MobileView_title {
  position: absolute;
  width: 182px;
  height: 86px;
  left: 24px;
  top: 46px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  color: var(--brand-dark-green);
}

.MobileView {
  position: absolute;
  width: 309px;
  height: 531px;
  left: 24px;
  top: 171px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
}

.logo_mobileview {
  position: absolute;
  width: 86px;
  height: 49px;
  left: 252px;
  top: 452px;
}
