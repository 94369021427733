.infoBox_step1 {
  padding: 7rem 7rem 1rem 0;
  width: 90%;
  padding: 2rem;
  border: 2px solid var(--brand-lighter-green);
  margin: 2rem;
}

.infoBox_Index {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  font-size: 1rem;
  margin-left: -0.75rem;
}

.infoBox_Index h3 {
  background: var(--brand-yellow);
  width: 87%;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 60px solid var(--brand-yellow);
}
