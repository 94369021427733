
/* /////////content_main////// */

.content_main > h1, .glossaryBox > h1{
    width: 100%;
    font-size: 3rem;
    color: rgba(73, 170, 73, 0.575);
}

.content_main p{
    font-size: 1,5rem;
}

.content_main > h1, .content_main > h3{
     margin: 1.5rem 0;
}

.content_main p, .glossaryBox p, article p{
    margin: 1rem 0;
}

