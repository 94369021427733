.header_title {
  display: flex;
  justify-content: space-between;
  width: auto;
  font-size: 1rem;

}
.header_title h3 {
  font-size: 1.2rem;
}
.nav {
  width: 50%;
  display: flex;
  justify-content: space-between;
  padding: 3rem 6rem 1rem 8rem;
}

.logoSec {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  background: #EFEFEF;
  padding: 1rem 3rem 1rem 1rem;
  flex-direction: row;
}

.nav_logo_text {
  color: #858585;
  align-self: flex-end;
  font-size: 0.8rem;
  position: relative
}

.nav_left_column {
  flex: 1;
  text-align: left;
}

.nav_right_column {
  flex: 1;
  text-align: right;
}

.nav_logo {
  display: block;
  height: 60px;
  align-self: flex-end 
}

.form {
  margin-left: 2rem;
  margin-top: 2rem;
}

.link-inHeader{
  border: none;
  background: none;
}


.link_font{
  font-size: 1rem;
  font-weight: bold;

}